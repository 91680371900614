import React from 'react';
import Cookies from "universal-cookie";
import {withRouter} from "react-router";

class Header extends React.Component {

    constructor(props, context) {
        super(props, context);
        const cookies = new Cookies();
        this.state = {
            isLoggedIn: !!cookies.get('2wtrade-token'),
            logoutButton: (<></>)
        };

        this.cookies = new Cookies();
        this.cookieUpdated = this.cookieUpdated.bind(this);
/*        this.props.customHistory.listen(location => {

        });*/
    }

    handleLogoutClick() {
        const cookies = new Cookies();
        const cookieDomain = document.location.hostname.match(/\w+\.\w+$/)[0];
        cookies.remove('2wtrade-token', {path: '/', domain: '.' + cookieDomain});
        this.props.history.push('/');
        return false;
    }


    componentDidMount() {
        this.cookieUpdated();
    }

    cookieUpdated() {
        const cookies = new Cookies();
        if (!cookies.get('2wtrade-token')) {
            this.setState({
                isLoggedIn: false,
                logoutButton: (<></>)
            });
            return;
        }
        this.setState({
            isLoggedIn: true,
            logoutButton: (<button onClick={() => {
                this.handleLogoutClick();
                this.cookieUpdated();
            }}>Выйти</button>)
        });
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark static-top">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img className="mr-2" src="/logo.png" width="45" height="45" alt=""/>
                        2WTrade login
                    </a>
                    {this.state.logoutButton}
                </div>
            </nav>
        );
    }
}

export default withRouter(Header);