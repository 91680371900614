import React from 'react';

export default class PageError404 extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div>404 &mdash; Not Found</div>
        );
    }
}