import React from 'react';
import Cookies from "universal-cookie";
import axios from "axios";
import ServiceInfoBlock from "../elements/ServiceInfoBlock";


export default class PageServices extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            error: null,
            isLoaded: false,
            strings: null
        };
        this.state = {
            error: null,
            isLoaded: false,
            token: false,
            user: false,
            services: []
        };
        const cookies = new Cookies();
        if (!cookies.get('2wtrade-token')) {
            document.location.assign('/');
            return;
        }
        this.state.token = cookies.get('2wtrade-token');
    }

    componentDidMount() {
        const loc = document.location;
        axios.post(loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port : '') + '/api/', {
            action: 'validate',
            token: this.state.token
        }).then((response) => {
            //console.log(response);
            if (response.data.status !== 'OK') {
                // error handler here
                console.log('ERROR: ', response.data);
                return;
            }
            let servicesList = [];
            for (const serviceItem of response.data.data.account.services){
                servicesList.push({
                    id: serviceItem.id,
                    name: serviceItem.name,
                    hostname: serviceItem.hostname,
                    port: parseInt(serviceItem.port)
                });
            }
            //console.log(servicesList);
            this.setState({
                user: response.data.data.account.email,
                isLoaded: true,
                services: servicesList
            });

        });
    }

    render() {
        const {error, isLoaded} = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div className="mt-5"><img src="/loader.gif" alt="loading"/></div>;
        }
        //console.log(this.state.services);
        this.items = this.state.services.map((item, key)=><ServiceInfoBlock key={key} name={item.name} hostname={item.hostname} port={item.port} />);
        return (
            <>
                <div className="container mt-3">
                    <div className="h3">Logged in as {this.state.user}</div>
                <div className="h3">Select a service</div>
                    <div className="container">
                        <div className="row">
                            <ul className="ds-btn">{this.items}</ul>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}