import React from 'react';
import Button from "../elements/Button";
import * as BSDir from '../directories/bootstrap-types';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {ReCaptcha} from 'react-recaptcha-google';
import ENV from '../../env.js';

export default class PageHome extends React.Component {
    captchaLogin;

    constructor(props, context) {
        super(props, context);

        this.urlQuery = (new URL(document.location)).searchParams;
        if (this.urlQuery.get("return_to")) {
            localStorage.setItem('return_url', this.urlQuery.get("return_to"))
        }

        this.state = {
            error: null,
            isLoaded: false,
            showCaptcha: false
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        const cookies = new Cookies();
        if (cookies.get('2wtrade-token')) {
            this.props.history.push('/services');
            return;
        }
        this.setState({isLoaded: false});
        const loc = document.location;
        axios.get(loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port : '') + '/api/?action=check_captcha_needed').then((response) => {
            if (response.data.status !== 'OK') {
                console.log('ERROR: ', response.data);
                this.setState({
                    requestError: response.message,
                    isLoaded: true,
                    showCaptcha: response.data.data.show_captcha,
                    captchaAnswer: null
                });
                return;
            }
            this.setState({
                requestError: false,
                isLoaded: true,
                showCaptcha: response.data.data.show_captcha
            });
            if (this.captchaLogin) {
                console.log("started, just a second...");
                this.captchaLogin.reset();
            }
        });


    }

    onLoadRecaptcha() {
        if (this.captchaLogin) {
            this.captchaLogin.reset();
        }
    }

    verifyCallback(recaptchaToken) {
        console.log('Got captcha token', recaptchaToken);
        this.setState({captchaAnswer: recaptchaToken});
    }

    render() {
        if (!this.state.isLoaded) {
            return <div className="mt-5"><img src="/loader.gif" alt="loading"/></div>;
        }

        let errorElement = (<></>);
        let captcha = (<></>);

        if (this.state.showCaptcha) {
            captcha = (<div className="mb-2 text-center"><ReCaptcha
                ref={(el) => {
                    this.captchaLogin = el;
                }}
                size="normal"
                data-theme="light"
                render="explicit"
                sitekey={ENV.RECAPTCHA_KEY}
                onloadCallback={this.onLoadRecaptcha}
                verifyCallback={this.verifyCallback}
            /></div>);
        }
        if (this.state.requestError !== false) {
            errorElement = (
                <div id="errorFrame" className="row">
                    <div className="mx-auto bg-danger text-white p-2 mb-2">
                        <div className="h3">Login error:</div>
                        <div className="mt-1">{this.state.requestError}</div>
                    </div>
                </div>
            );
        } else if (!this.state.isLoaded) {
            return (<div className="mt-5"><img src="/loader.gif" alt="Loading..."/></div>);
        }
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                            <div className="card card-signin my-5 border border-grey shadow-lg bg-white rounded">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Sign In</h5>
                                    {errorElement}
                                    <div className="form-signin">
                                        <div className="form-label-group">
                                            <input type="email" id="inputEmail" className="form-control" placeholder="Email address" onChange={(e) => {
                                                this.email = e.target.value;
                                            }} autoFocus/>
                                        </div>
                                        <div className="form-label-group">
                                            <input type="password" id="inputPassword" className="form-control" placeholder="Password" onChange={(e) => {
                                                this.password = e.target.value;
                                            }}/>
                                        </div>
                                        {captcha}
                                        <Button caption={"Sign in"} classes={"btn-lg btn-block text-uppercase"} bsstyle={BSDir.styles.PRIMARY} click={() => {
                                            this.validate();
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }


    validate() {
        //fetch()
        if (this.state.showCaptcha && !this.state.captchaAnswer) {
            alert('Solve captcha first');
            return false;
        }
        this.setState({
            isLoaded: false,
            requestError: false,
        });
        const loc = document.location;
        axios.post(loc.protocol + '//' + loc.hostname + (loc.port ? ':' + loc.port : '') + '/api/', {
            action: 'login',
            login: this.email,
            password: this.password,
            recaptcha: this.state.captchaAnswer
        }).then((response) => {
            this.setState({captchaAnswer: null});
            if (response.data.status !== 'OK') {
                // error handler here
                console.log('ERROR: ', response.data);
                console.log(response);
                this.setState({
                    isLoaded: true,
                    requestError: response.data.message,
                    showCaptcha: response.data.data.show_captcha ? response.data.data.show_captcha : false
                });
                return;
            }
            const cookieDomain = loc.hostname.match(/\w+\.\w+$/)[0];
            const cookies = new Cookies();
            cookies.set('2wtrade-token', response.data.data.token, {path: '/', domain: '.' + cookieDomain});
            if (localStorage.getItem('return_url')) {
                const url = localStorage.getItem('return_url');
                localStorage.removeItem('return_url');
                document.location.assign(url);
                return;
            }

            this.props.history.push('/services');
        });

    }
}