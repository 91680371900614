import React from 'react';
import {Route, Switch} from "react-router";
import {BrowserRouter} from 'react-router-dom';
import './styles/custom.scss';
import './styles/App.css';
import Header from './components/blocks/header';
import Footer from './components/blocks/footer';

import PageHome from "./components/pages/home";
import PageServices from "./components/pages/services";
import PageError404 from "./components/pages/error404";
import {createBrowserHistory} from "history";

function App() {
    const History = createBrowserHistory();
    History.listen((v1,v2,v3)=>{
        console.log(v1,v2,v3);
    });
    const CompHeader = <Header customHistory={History}/>;
    return (
        <div className="App">
            <BrowserRouter>
                {CompHeader}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <>
                                <Switch>
                                    <Route exact path="/" component={PageHome} />
                                    <Route exact path="/services" component={PageServices}/>
                                    <Route exact path="*" component={PageError404}/>
                                </Switch>
                            </>
                        </div>
                    </div>
                </div>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
