import React, {Component} from "react";
import * as BSDir from '../directories/bootstrap-types';

export default class Button extends Component {


    constructor(props, context) {
        super(props, context);
        this.bsstyle = this.props.bsstyle ? this.props.bsstyle : BSDir.styles.PRIMARY;
    }

    render() {
        return (
            <button className={"btn btn-" + this.bsstyle + " " + this.props.classes} onClick={()=>{
                this.props.click()
            }}>{this.props.caption}</button>
        );
    }
}