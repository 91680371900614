
const styles = {
    PRIMARY: 'primary',
    SECONDARY:'secondary',
    SUCCESS:'success',
    DANGER:'danger',
    WARNING:'warning',
    INFO:'info',
    LIGHT:'light',
    DARK:'dark',
};

export {styles};