import React, {Component} from "react";

export default class ServiceInfoBlock extends Component {

    render() {
        return (
            <>
                <li className="service-link">
                    <a className="btn btn-lg bg-light border border-grey shadow" href={"http://" + this.props.hostname + (this.props.port !== 80 ? ':' + this.props.port : '')}>
                        <img src={'/icons/'+this.props.hostname+'.png'} alt={this.props.name} className={"service-image"}/>
                        {this.props.name}<br/><small>{this.props.hostname}</small>
                    </a>
                </li>
            </>
        );
    }
}