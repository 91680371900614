import React from 'react';

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="page-footer bg-light font-small blue">
                <div className="static-bottom footer-copyright text-right py-1 pr-2">
                    <a href="https://www.2wtrade.com/">&copy; 2019 Copyright, 2Wtrade.com</a>
                </div>
            </footer>
        );
    }
}